import SimpleBar from 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
export default class Menu {
    /**
     * Constructor
     */
    constructor() {
        this.$menuRootItems = $('.main-nav .menu-item');
        this.$linkActivity = $('.activites-item .activity-menu-item');
        this.$submenuPanel = $('.submenu-panel');
        this.$banner = $('.banner');
        this.$band = $('.header-menu-wrapper > .border-left');
        this.$submenuListWrapper = $('.submenu-list-wrapper');
        this.$submenuMegaOptionText = $('.submenu-mega-option-text');
        this.$submenuWrapper = $('.submenu-wrapper');
        this.$menuBurger = $('.menu-burger');
        this.$menuFooterList = $('.menu-footer-list');
        this.$topBar = $('.top-bar');
        this.$currentTarget = null;
        /*this.breakpoint = window.matchMedia( '(min-width:768px)' );
        this.breakpoint.addEventListener("change", (e) => {
            this.breakpointChecker();
        });
        this.breakpointChecker();*/
    }

    init () {
        /**
         * Menu root event click
         */
        this.$menuRootItems.on ('click', (e) => {
            if (this.$currentTarget !== e.currentTarget) {
                this.selectMenu(e.currentTarget);
                this.$currentTarget = e.currentTarget;
            } else {
                this.closeMenu();
            }

            return false;
        });

        if ( this.$linkActivity.length > 0 ) {
            this.$linkActivity.on ('click', (e) => {
                this.selectMenu(e.currentTarget);
                $('body').addClass('menu-activity');

                return false;
            });
        }


        /**
         * Search button click
         */
            $('.btn-search').on('click', (e) => {

                $('.modal').addClass('visible');
                $('.overlay').addClass('visible');
                $('.banner').hide();
                $('.top-bar').hide();

                return false;
            });

            $('.close').on('click', (e) => {

                $('.modal').removeClass('visible');
                $('.overlay').removeClass('visible');
                $('.banner').show();
                $('.top-bar').show();

                return false;
            });

            $('.overlay').on('click', function (event) {
                if (event.target === this) {
                    $('.modal').removeClass('visible');
                    $('.overlay').removeClass('visible');
                    $('.banner').show();
                    $('.top-bar').show();
                }
            });

        /**
         * Menu close event click
         */

        $('.menu-close, .overlay').on ('click', (e) => {
            this.closeMenu();
            return false;
        })

        $(document).on(
        'keydown', (event)=> {
            if (event.key == "Escape") {
                this.closeMenu();
            }
        });

        /**
         * submenu close event click
         */

        $('.menu-back').on ('click', (e) => {
            $('body').removeClass('submenu-mobile-open');
            this.$submenuPanel.removeClass('active');
            this.$menuRootItems.removeClass('active');
            this.$currentTarget = null;

            return false;
        })

        /**
         * Fixed menu on document scroll
         */

        $(document).on('scroll', () => {
            if ($(window).scrollTop() > this.$banner.offset().top) {
                $('body').addClass('main-menu-fixed');
            }
            if ($(window).scrollTop() == 0) {
                $('body').removeClass('main-menu-fixed');
            }
        });

        $(document).on('scroll', () => {
            if (window.matchMedia("(max-width: 992px)").matches) {
                if ($(window).scrollTop() > 0) {
                    $('body').addClass('header-mobile-fixed');
                } else {
                    $('body').removeClass('header-mobile-fixed');
                }
            } else {
                $('body').removeClass('header-mobile-fixed');
            }
        });

        var previousScrollTop = $(window).scrollTop();
        $(document).on('scroll', () => {
            var currentScrollTop = $(window).scrollTop();
                if (window.matchMedia("(min-width: 992px)").matches) {
                    if ((currentScrollTop < previousScrollTop) && (currentScrollTop > this.$banner.offset().top)) {
                        $('body').addClass('main-top-fixed');
                        $('body').addClass('header-fixed');
                    }
                    if ((currentScrollTop >= previousScrollTop) && (currentScrollTop > this.$banner.offset().top) || currentScrollTop == 0 ) {
                        $('body').removeClass('header-fixed');
                        $('body').removeClass('main-top-fixed');
                    }

                    /*if (currentScrollTop == 0) {
                        $('body').removeClass('header-fixed');
                        $('body').removeClass('main-top-fixed');
                    }*/
                }
                previousScrollTop = currentScrollTop;
            });


        /**
         * Displaing submenu item
         */

        const observer = new IntersectionObserver(entries => {
            entries.forEach( entry => {
                // const intersecting = entry.isIntersecting;
                if (entry.isIntersecting) {
                    $(entry.target).addClass('visible');
                } else {
                    $(entry.target).removeClass('visible');
                }
                // console.log (entry.target, intersecting);
            })
        }, {
            root: $('.submenu').get(0),
            threshold: 1,
            rootMargin: '0px 0px -24px 0px'
        })

        const $submenuItems = $('.submenu-item');
        $submenuItems.each ( (i, item) => {
            observer.observe(item);
        });

        this.initMenuMobile();
        this.initSubmenuSCroll();
        this.initMenuFooter();
    }

    selectMenu ( _target ) {
        const $menuItem = $(_target);
        const $target = $('.submenu-panel' + $menuItem.attr('href'));

        const bgcolor = $target.length > 0  ? $target.data('color') : null;

        if ( $target.length > 0 ) {
            $('body').addClass('submenu-open submenu-mobile-open');
            this.$band.css('background', bgcolor);
            this.$submenuPanel.removeClass('active');
            this.$menuRootItems.removeClass('active');

            $target.addClass('active');
            $menuItem.addClass('active');
        }
    }

    initSubmenuSCroll () {
        let submenuListWrapper = new Array() ;
        let submenuMegaOptionText = new Array() ;

        this.$submenuListWrapper.each ( (index, el) =>  {
            submenuListWrapper[index] = new SimpleBar(el, {
                autoHide: false
            })
        });

        this.$submenuMegaOptionText.each ( (index, el) =>  {
            submenuMegaOptionText[index] = new SimpleBar(el, {
                autoHide: false
            })
        });
    }

    initMenuMobile () {
        this.$menuBurger.on ('click', (e) => {
            $('body').addClass('submenu-open');
            $('.top-bar').hide();
            return false;
        });
    }

    destroySimpleBar ( elem, parent ) {
        const wrapper = elem.getContentElement();
        let $content = $('nav', wrapper).clone();

        // console.log ($content.get(0), parent);
        $(parent).empty().append($content);

    }

    initMenuFooter () {
        this.$menuFooterList.each( (index, elem) => {
            $('.footer-title', elem).on('click', () => {
                $(elem).toggleClass('open');

                return false;
            })
        })
    }

    closeMenu () {
        $('body').removeClass('submenu-open');
        $('body').removeClass('submenu-mobile-open');
        $('body').removeClass('menu-activity');
        $('.top-bar').show();
        this.$submenuPanel.removeClass('active');
        this.$menuRootItems.removeClass('active');

        this.$currentTarget = null;
    }
}
