window.$ = jQuery;
window.tarteaucitronCustomText = {
    'alertBigPrivacy': "La Chambre de Commerce et d'Industrie Nice Côte d'Azur utilise les cookies pour améliorer votre expérience et la navigation sur notre site, pour réaliser des statistiques d’audience et études pour améliorer nos offres, pour vous proposer des services adaptés à vos centres d’intérêt.<br> Certains cookies sont nécessaires au fonctionnement du site et de nos services. Vous pouvez accepter, gérer vos cookies ou continuer votre navigation sans accepter.",
};

import 'simplebar/dist/simplebar.css';
import '../styles/app.scss';

// You will need a ResizeObserver polyfill for browsers that don't support it! (iOS Safari, Edge, ...)
// import ResizeObserver from 'resize-observer-polyfill';
import Router from './util/Router';
import common from './routes/common';
import aboutUs from './routes/about';
import page from './routes/page';
import taxRubric from './routes/tax-rubric';
import transentreprise from './routes/transentreprise';
import woocommerceAccount from './routes/woocommerce-account';

// window.ResizeObserver = ResizeObserver;

/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    common,
    page,
    taxRubric,
    // About Us page, note the change from about-us to aboutUs.
    aboutUs,
    transentreprise,
    woocommerceAccount
});

// Load Events
$(document).ready(() => routes.loadEvents());
