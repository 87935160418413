export default class Accordion {
    init () {
        const $accordionItems = $('.accordion-item');

        $accordionItems.each( (index, item) => {
            const $header = $('.accordion-header', item);
            $header.on('click', () => {
                $(item).toggleClass('expand');

                return false;
            });
        });
    }
}
