import Accordion from "../components/Accordion";
import Swiper from 'swiper';
import {Autoplay, Controller, Navigation, Pagination} from 'swiper/modules';
import TaxRubric from "./tax-rubric";

export default {
    init() {
        // JavaScript to be fired on the about us page
        let accordion = new Accordion();
        accordion.init();
        this.initSliders();
        this.closePopin();
        this.initPopinVideo();
        this.initTabActusEvents();
        this.initActivities();

        if ( $('.selectpicker').length > 0 ) {
            $.fn.selectpicker.Constructor.DEFAULTS.multipleSeparator = ' | ';
        }

        if($('body').hasClass('page-template-template-transentreprise')){
            if($('#mapSearch').length > 0){
                this.initMap('mapSearch');
            }
        }

        if ($('.search-result-list').length > 0) {
            $('.search-result-paginate').each((index, elem) => {
                $(elem).easyPaginate({
                    paginateElement: '.paginate-item',
                    elementsPerPage: 10,
                    effect: 'slide',
                    firstButton: false,
                    lastButton: false,
                    prevButtonText: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="chevron-right">\n' +
                        '                                <path d="M10 16L14 12L10 8" stroke="#010101" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
                        '                            </svg>',
                    nextButtonText: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="chevron-right">\n' +
                        '                                <path d="M10 16L14 12L10 8" stroke="#010101" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
                        '                            </svg>'
                });
            });

            $('.easyPaginateNav .page-numbers').click( () => {
                $('html, body').animate({
                    scrollTop: $('.transentreprise-search-result').offset().top
                });
            })
        }

        /*if ($('.search-result-list').length > 0) {
            // console.log (List);
            let perPage = 4
            let searchList = new List('searchResult', {
                valueNames: ['search-result-item'],
                listClass: 'search-result-list',
                page: perPage,
                pagination: {
                    paginationClass: 'pages',
                    left: 1,
                    right: 1,
                    item: '<li><a class="page page-numbers" href="javascript:;"></a></li>'
                }
            })

            searchList.on('updated', (list) => {
                var isFirst = list.i == 1;
                var isLast = list.i > list.matchingItems.length - list.page;

                $('.nav-links .prev').removeClass('inactive');
                $('.nav-links .next').removeClass('inactive');

                if (isFirst) {
                    $('.nav-links .prev').addClass("inactive");
                }
                if (isLast) {
                    $('.nav-links .next').addClass("inactive");
                }

                // hide pagination if there one or less pages to show
                if (list.matchingItems.length <= perPage) {
                    $(".pagination-wrapper").hide();
                } else {
                    $(".pagination-wrapper").show();
                }
            })

            $('.nav-links .next').click(() => {
                let $next = $(".pages .active").next();
                $next.find('a').click();
                // searchList.show($('a', $next).data('i'), perPage);
                console.log(  $next);
            });

            $(".nav-links .prev").click(() => {
                $(".pages .active")
                    .prev()
                    .find('a')
                    .trigger("click");
            });

            searchList.update();
        }*/
    },

    initSliders () {
        /*
        *   portrait slider
        */

        const $swiperPortraits = $('.swiper.portrait-list');

        $swiperPortraits.each ( (index, elem) => {
            const swiperPortrait = new Swiper( elem, {
                loop: false,
                slidesPerView: 'auto',
                spaceBetween: 24,

                breakpoints: {
                    1024: {
                        slidesPerView: 'auto',
                        spaceBetween: 50,
                    },

                    1440: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    }
                }
            })
        });

        /*
        *  Key figures Slider
        */

        const $swiperNumbers = $('.swiper.number-list');
        if ($swiperNumbers.length > 0) {

            $swiperNumbers.each((index, elem) => {
                const swiperNumber = new Swiper(elem, {
                    loop: false,
                    slidesPerView: 1,
                    spaceBetween: 24,
                    centerInsufficientSlides: true,

                    breakpoints: {
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 50,
                        },

                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        }
                    }
                })
            });
        }

        /*
        * Galerie Slider
         */

        const $galeries = $('.galerie');
        // console.log($galeries);

        if ($galeries.length > 0) {
            $galeries.each((index, elem) => {
                const idGalery = $(elem).attr('data-swiper-target')
                const targetClass = '.swiper-' + idGalery;
                const $parent = $(elem).parents('.galerie-media').eq(0);
                const pagination = $('.titre-section .swiper-pagination', $parent).get(0);
                const btnPrev = $('.titre-section .swiper-button-prev', $parent).get(0);
                const btnNext = $('.titre-section .swiper-button-next', $parent).get(0);
                // console.log (targetClass);

                const swiperGalerie = new Swiper(elem, {
                    loop: false,
                    slidesPerView: 1,
                    spaceBetween: 16,
                    breakpoints: {
                        768: {
                            spaceBetween: 32,
                        },

                        1200: {
                            spaceBetween: 48,
                        }
                    },
                    pagination: {
                        el: pagination,
                        type: "fraction",
                        formatFractionCurrent: function (number) {
                            return ('0' + number).slice(-2);
                        },
                        formatFractionTotal: function (number) {
                            return ('0' + number).slice(-2);
                        },
                    },
                    navigation: {
                        nextEl: btnNext,
                        prevEl: btnPrev,
                    },

                    modules: [Pagination, Controller, Navigation]
                })

                const btnPrevPop = $( '#popin-' + idGalery + ' .swiper-button-prev').get(0);
                const btnNextPop = $( '#popin-' + idGalery + ' .swiper-button-next').get(0);

                const swiperGalerieFull = new Swiper( targetClass, {
                    loop: false,
                    slidesPerView: 1,
                    spaceBetween: 16,
                    on: {
                        slideChange: (swiper) => {
                            const $slideActive = $(swiper.slides[swiper.activeIndex]);
                            const $slidePrev = $(swiper.slides[swiper.previousIndex]);
                            console.log (swiper.el)

                            if ( $(swiper.el).parents('.popin-gallery').eq(0).hasClass('open-popin') ) {
                                this.renderIframe($slideActive);
                                this.removeIFrame ($slidePrev);
                            }
                        }
                    },
                    navigation: {
                        nextEl: btnNextPop,
                        prevEl: btnPrevPop,
                    },
                    modules: [Pagination, Controller, Navigation]
                })

                // sync 2 sliders
                if (idGalery) {
                    console.log('has popin');
                    swiperGalerieFull.controller.control = swiperGalerie;
                    swiperGalerie.controller.control = swiperGalerieFull;


                    $('.swiper-slide', elem).on('click', (e)=> {
                        if ( $(e.currentTarget).hasClass('swiper-slide-active') ) {
                            const $slideActive = $(swiperGalerieFull.slides[swiperGalerieFull.activeIndex]);
                            $('#popin-' + idGalery).addClass('open-popin');
                            this.renderIframe($slideActive);
                        }
                    })
                }
            });
        }

        /*
        * Cible slider
         */

        const $cibles = $('.cibles-list');
        if ($cibles.length > 0) {
            $cibles.each ( (index, elem) => {
                const $parent = $(elem).parents('.cibles').eq(0);
                const pagination = $('.titre-section .swiper-pagination', $parent).get(0);
                const btnPrev = $('.titre-section .swiper-button-prev', $parent).get(0);
                const btnNext = $('.titre-section .swiper-button-next', $parent).get(0);

                const swiperCible = new Swiper( elem, {
                    loop: false,
                    slidesPerView: 1.2,
                    spaceBetween: 24,

                    breakpoints: {
                        525: {
                            slidesPerView: 2,
                            spaceBetween: 24,
                        },

                        640: {
                            slidesPerView: 2.5,
                            spaceBetween: 36,
                        },

                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },

                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 50,
                        },

                        1440: {
                            slidesPerView: 5,
                            spaceBetween: 58,
                        }
                    },

                    pagination: {
                        el: pagination,
                        type: "fraction",
                        formatFractionCurrent: function (number) {
                            return ('0' + number).slice(-2);
                        },
                        formatFractionTotal: function (number) {
                            return ('0' + number).slice(-2);
                        },
                    },
                    navigation: {
                        nextEl: btnNext,
                        prevEl: btnPrev,
                    },

                    modules: [Pagination, Controller, Navigation]
                })
            });
        }

        /*
        * Maillage slider
         */

        const $maillage = $('.maillages-list');
        if ($maillage.length > 0) {
            $maillage.each ( (index, elem) => {
                const $parent = $(elem).parents('.maillages').eq(0);
                const pagination = $('.titre-section .swiper-pagination', $parent).get(0);
                const btnPrev = $('.titre-section .swiper-button-prev', $parent).get(0);
                const btnNext = $('.titre-section .swiper-button-next', $parent).get(0);

                const maillageCible = new Swiper( elem, {
                    loop: false,
                    slidesPerView: 1.2,
                    spaceBetween: 24,
                    centerInsufficientSlides: true,

                    breakpoints: {
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 36,
                        },

                        1200: {
                            slidesPerView: 2.5,
                            spaceBetween: 70,
                        },

                        1440: {
                            slidesPerView: 3,
                            spaceBetween: 70,
                        }
                    },

                    pagination: {
                        el: pagination,
                        type: "fraction",
                        formatFractionCurrent: function (number) {
                            return ('0' + number).slice(-2);
                        },
                        formatFractionTotal: function (number) {
                            return ('0' + number).slice(-2);
                        },
                    },
                    navigation: {
                        nextEl: btnNext,
                        prevEl: btnPrev,
                    },

                    modules: [Pagination, Controller, Navigation]
                })
            });
        }

        /*
        * Focus slider
         */

        const $focus = $('.focus-list');
        if ($focus.length > 0) {
            $focus.each ( (index, elem) => {
                const $parent = $(elem).parents('.focus-slider').eq(0);
                const pagination = $('.titre-section .swiper-pagination', $parent).get(0);
                const btnPrev = $('.titre-section .swiper-button-prev', $parent).get(0);
                const btnNext = $('.titre-section .swiper-button-next', $parent).get(0);

                const focusSlider = new Swiper( elem, {
                    loop: true,
                    slidesPerView: 1,
                    spaceBetween: 24,
                    simulateTouch: false,

                    autoplay: {
                        delay: 4000,
                        pauseOnMouseEnter: true
                    },

                    breakpoints: {
                        768: {
                            spaceBetween: 36,
                        },

                        1200: {
                            spaceBetween: 70,
                        },

                        1440: {
                            spaceBetween: 70,
                        }
                    },

                    pagination: {
                        el: pagination,
                        type: "fraction",
                        formatFractionCurrent: function (number) {
                            return ('0' + number).slice(-2);
                        },
                        formatFractionTotal: function (number) {
                            return ('0' + number).slice(-2);
                        },
                    },
                    navigation: {
                        nextEl: btnNext,
                        prevEl: btnPrev,
                    },

                    modules: [Pagination, Controller, Navigation, Autoplay]
                })
            });
        }
    },

    initTabActusEvents () {
        if ( $('.actus-events').length > 0 ) {
            $('.actus-events .tab-nav a').each ( (index, elem) => {
                $(elem).on('click', () => {
                    if ( !$(elem).hasClass('active') ) {
                        $('.actus-events .tab-nav a').removeClass('active');
                        $('.actus-events .tab-content .active').removeClass('active');

                        let target =  $(elem).data('target');
                        $(elem).addClass('active');
                        $('.actus-events .tab-content .' + target).addClass('active');
                    }
                });
            });
        }
    },

    initPopinVideo () {
        $('.btn-video').each ( (index, elem) => {
            $(elem).on('click', () => {
                const target = $(elem).attr('href');
                $(target).addClass('open-popin');
                this.renderIframe($('.swiper-slide', target));

                return false;
            })
        });
    },

    renderIframe ($container) {
        if ($container.is("[data-video-src]")) {
            const src = $container.attr('data-video-src');

            let $iframe = $('<iframe>');
            $iframe
                .attr('src', src + '?autoplay=1&mute=1')
                .attr('frameborder', 0)
                .attr('allowfullscreen', 'allowfullscreen')
                .attr('allow', 'autoplay; fullscreen');

            $('.photo', $container).append($iframe);
        }
    },

    removeIFrame ($container) {
        if ($('iframe', $container).length > 0) {
            $('iframe', $container).remove();
        }
    },

    closePopin () {
        $('.popin-gallery .btn-close').on('click', (e)=> {
            $(e.currentTarget).parents('.popin-gallery').eq(0).removeClass('open-popin');
            // $('#popin-' + idGalery).removeClass('open-popin');
            this.removeIFrame($(e.currentTarget).parents('.popin-gallery').eq(0));
        });
    },

    initActivities () {
        let $activity_item = $('.activites-item');
        console.log($activity_item);
        if ( $activity_item.length > 0 ) {
            const observer = new IntersectionObserver(entries => {
                entries.forEach( entry => {
                    // const intersecting = entry.isIntersecting;
                    if (entry.isIntersecting) {
                        $(entry.target).addClass('visible');
                    } else {
                        $(entry.target).removeClass('visible');
                    }
                    // console.log (entry.target, intersecting);
                })
            }, {
                threshold: 0.5,
            });

            $activity_item.each ( (i, item) => {
                observer.observe(item);
            });
        }
    },

    initMap(idMap) {

        // Coordonnées centre PACA
        // var latitude = 43.9351691;
        var latitude = 41.876;
        // var longitude = 6.0679194;
        var longitude = -87.624;

        var map = new google.maps.Map(document.getElementById(idMap), {
            zoom: 11,
            center: {lat: latitude, lng: longitude}
        });

        var ctaLayer = new google.maps.KmlLayer({
            url: 'http://www.transentreprise.com/kml/region/PAC.kml',
            map: map
        });
    },

    finalize () {
        if ( $('.rubric-solutions').length > 0) {
            TaxRubric.init();
        }

       /* $('iframe').iFrameResize ({
            log: true
        });*/
    }
};
