export default {
  init() {
// Coordonnées centre PACA
      const latitude = 43.9351691;
      const longitude = 6.0679194;

      if ( $('#map').length > 0 ) {
          // 43.71482982751375, 7.235643068358668
          let city = $('#map').attr('data-city');
          let kml = $('#map').attr('data-kml');
          console.log(city, kml);

          if (city != '') {
              const centre = new google.maps.LatLng(latitude, longitude);
              const mapOptions = {
                  center: centre,
                  zoom: 10,
                  mapTypeId: google.maps.MapTypeId.ROADMAP
              };

              let map = new google.maps.Map(document.getElementById("map"), mapOptions);

              let geocoder = new google.maps.Geocoder();
              geocoder.geocode({'address': city}, function (results, status) {
                  let position = results[0].geometry.location;
                  let marker = new google.maps.Marker({
                      map: map,
                      position: position,
                      title: city
                  });
              });

              if (kml != '') {
                  var kmlLayer = new google.maps.KmlLayer(kml, {
                      suppressInfoWindows: true,
                      preserveViewport: false,
                      map: map
                  });
              }
          }
      }

      $('.btn-like-annonce').on('click', () => {
          // console.log ('click');
          $('.annonce-form form').slideDown();
      })

      $('.btn-print').on('click',(e) => {
          e.preventDefault();
          window.print();
      });
  },
};
